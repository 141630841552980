import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getCategoriesWithMaster,
  getGroups,
  getParcels,
} from "../../features/groups/GroupsSlice";
import { BackSvg, DocumentSvg } from "../../components/icons";
import Checkbox from "../../components/common/Checkbox";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../libs/AppContext";

const ParcelDetails = () => {
  const [openedParcel, setOpenedParcel] = useState({});
  const [parcelGroupId, setParcelGroupId] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const { parcels, categoriesWithMaster } = useSelector(
    (state) => state.groups
  );
  const { setParcelId } = useAppContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setParcelId(id);
  }, [id, setParcelId]);

  useEffect(() => {
    const selectedParcelGroupId = localStorage.getItem("parcelGroupId");
    setParcelGroupId(selectedParcelGroupId);
    if (selectedParcelGroupId) {
      dispatch(
        getParcels({
          parcelGroupId: selectedParcelGroupId,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const selectedCountryId = localStorage.getItem("selectedCountryId");
    if (selectedCountryId) {
      dispatch(
        getGroups({
          warehouseCountryId: selectedCountryId,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
  }, [dispatch]);

  useEffect(() => {
    const opened = parcels?.parcels?.find(
      (parcel) => parseInt(parcel.id) === parseInt(id)
    );
    setOpenedParcel(opened);
  }, [id, parcels]);

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster]);

  useEffect(() => {
    if (openedParcel?.warehouseCategoryId && categoriesWithMaster.length > 0) {
      const subCategory = subCategories.find(
        (sub) => sub.id === openedParcel.warehouseCategoryId
      );
      if (subCategory) {
        const parentCategory = categoriesWithMaster.find((category) =>
          category.children?.some((child) => child.id === subCategory.id)
        );
        setParentCategory(parentCategory?.description || t("text.unknown"));
      }
    }
  }, [openedParcel, subCategories, categoriesWithMaster, t]);

  const getSubCategoryDescription = (categoryId) => {
    const subCategory = subCategories.find((sub) => sub.id === categoryId);
    return subCategory ? subCategory.description : t("text.unknown");
  };

  const handleOpenFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const calculateTotals = () => {
    const totalQuantity = openedParcel?.parcelDetails?.reduce(
      (total, detail) => total + (parseFloat(detail.quantity) || 0),
      0
    );
    const totalWeight = openedParcel?.parcelDetails?.reduce(
      (total, detail) => total + (parseFloat(detail.weight) || 0),
      0
    );

    return { totalQuantity, totalWeight };
  };

  const { totalQuantity, totalWeight } = calculateTotals();

  return (
    <div>
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to={`/ParcelGroup/${parcelGroupId}`}
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          {openedParcel && (
            <div className="text-2xl font-semibold text-gray-800">
              <span className="font-normal">{openedParcel.fullName}</span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-start gap-6 p-6">
        <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
          <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
            {openedParcel?.tdsCode}
          </div>
          <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
            {openedParcel?.roomNumber}
          </div>
        </div>

        {openedParcel?.parcelDetails?.map((detail, index) => (
          <div
            key={index}
            className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl md:grid-cols-5"
          >
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
              {detail.weight}
            </div>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
              {detail.quantity}
            </div>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
              {detail.length}
            </div>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
              {detail.width}
            </div>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
              {detail.height}
            </div>
          </div>
        ))}

        <div className="w-full grid grid-cols-1 gap-4 rounded-xl lg:grid-cols-2">
          <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-4 px-3 leading-5 text-sm text-black/50 ">
            {parentCategory}
          </div>
          <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-4 px-3 leading-5 text-sm text-black/60 ">
            {getSubCategoryDescription(openedParcel?.warehouseCategoryId)}
          </div>
        </div>

        <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
          <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
            ჯამური რაოდენობა {totalQuantity}
          </div>
          <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
            ჯამური წონა {totalWeight}
          </div>
        </div>

        {openedParcel?.parcelServices && (
          <div className="w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
            {openedParcel?.parcelServices.map((service, index) => (
              <div
                key={index}
                className="flex justify-start items-center gap-2 lg:justify-center"
              >
                <Checkbox
                  checked={service.serviceStatusFlag === "Y" ? true : false}
                />
                <span className="text-xs text-gray-400">
                  {service.serviceDesc}
                </span>
              </div>
            ))}
          </div>
        )}

        {openedParcel?.parcelFiles && (
          <div className="w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
            {openedParcel?.parcelFiles?.map((parcelFile, index) => (
              <div
                key={index}
                className="flex items-center gap-3 w-full bg-white rounded-xl py-2 px-4 cursor-pointer"
              >
                <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                  <DocumentSvg />
                </div>
                <h3
                  onClick={() => handleOpenFile(parcelFile.fileUrl)}
                  className="text-sm font-medium text-blue-900 truncate w-[calc(100%-40px)]"
                >
                  {parcelFile.fileName}
                </h3>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ParcelDetails;
