import { CloseModalSvg, SearchSvg } from "../icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const TableOutsideSearch = ({
  formData,
  setFormData,
  searchKey,
  placeholderValue,
}) => {
  const [searchTerm, setSearchTerm] = useState(formData[searchKey] || "");

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData((prevData) => ({
      ...prevData,
      [searchKey]: searchTerm,
      page: 1,
    }));
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFormData((prevData) => ({
      ...prevData,
      [searchKey]: "",
      page: 1,
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="relative w-full flex justify-between items-center gap-2 px-4 bg-gray-9 border border-gray-1/15 rounded-xl"
    >
      <span className="w-4 min-w-4">
        <SearchSvg />
      </span>
      <input
        className="bg-transparent outline-none text-sm w-full py-2 "
        type="text"
        placeholder={placeholderValue ? placeholderValue : t("text.search")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {searchTerm !== "" && (
        <span
          className="cursor-pointer w-4 min-w-4"
          onClick={handleClearSearch}
        >
          <CloseModalSvg />
        </span>
      )}
    </form>
  );
};

export default TableOutsideSearch;
