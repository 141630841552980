import { ReactBarcode as Barcode } from "react-jsbarcode";

const ParcelPrint = ({ printRef, data, formData }) => {
  return (
    <div ref={printRef} className="hidden max-w-md w-full bg-white p-6">
      <div className="p-10 min-h-screen h-full w-full flex flex-col items-center justify-center">
        <h2 className="text-lg font-semibold text-blue-900 mb-2">
          {data.fullName}
        </h2>
        <span className="text-sm text-gray-700">{data.roomNumber}</span>
        <Barcode
          value={formData.tds_code ? formData.tds_code : "DEFAULT"}
          format="CODE128"
          displayValue={true}
          lineColor="#000"
          width={2}
          height={40}
          textAlign="center"
          margin={10}
        />
      </div>
    </div>
  );
};

export default ParcelPrint;
