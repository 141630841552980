import { renderRoutes } from "./generate-routes";

import AnonymousLayout from "../layouts/AnonymousLayout";
import MainLayout from "../layouts/MainLayout";

import SignIn from "../pages/SignIn";
import Projects from "../pages/Projects";
import Invoice from "../pages/Invoice";
import FileCategories from "../pages/FileCategories";
import Departments from "../pages/Departments";
import Delegation from "../pages/Delegation";
import Clients from "../pages/Clients";
import Companies from "../pages/Companies";
import Categories from "../pages/Categories";
import GroupsList from "../pages/Groups/GroupsList";
import GroupParcels from "../pages/Groups/GroupParcels";
import ParcelDetails from "../pages/Groups/ParcelDetails";
import ResponseTimeDetails from "../pages/Dashboard/ResponseTime";
import MainPage from "../pages/Dashboard/MainPage";
import DeliveryTimeDetails from "../pages/Dashboard/DeliveryTime";
import SatisfactionScoreDetails from "../pages/Dashboard/SatisfactionScore";
import RacesProgressDetails from "../pages/Dashboard/RacesProgress";
import IssuedParcelsDetails from "../pages/Dashboard/IssuedParcels";
import Profile from "../pages/Profile";

export const routes = [
  {
    layout: AnonymousLayout,
    routes: [
      {
        name: "signin",
        title: "SignIn page",
        component: SignIn,
        path: "/",
        isPublic: true,
      },
    ],
  },
  {
    layout: MainLayout,
    routes: [
      {
        name: "dashboard",
        title: "Dashboard main page",
        component: MainPage,
        path: "/MainPage",
      },
      {
        name: "projects",
        title: "Projects page",
        component: Projects,
        path: "/projects",
      },
      {
        name: "invoice",
        title: "Invoice page",
        component: Invoice,
        path: "/invoice",
      },
      {
        name: "filecategories",
        title: "File Categories",
        component: FileCategories,
        path: "/delegationFileCategory",
      },
      {
        name: "departments",
        title: "Departments",
        component: Departments,
        path: "/delegationDepartments",
      },
      {
        name: "delegation",
        title: "Delegation",
        component: Delegation,
        path: "/delegation",
      },
      {
        name: "clients",
        title: "Clients",
        component: Clients,
        path: "/clients",
      },
      {
        name: "categories",
        title: "Categories",
        component: Categories,
        path: "/delegationCategory",
      },
      {
        name: "companies",
        title: "Companies",
        component: Companies,
        path: "/CompanyData",
      },
      {
        name: "groupList",
        title: "List",
        component: GroupsList,
        path: "/ParcelGroup",
      },
      {
        name: "parcels",
        title: "Parcels",
        component: GroupParcels,
        path: "/ParcelGroup/:id",
      },
      {
        name: "parcels",
        title: "Parcels",
        component: ParcelDetails,
        path: "/ParcelGroup/:id/:id",
      },
      {
        name: "responseTimeDetails",
        title: "responseTimeDetails",
        component: ResponseTimeDetails,
        path: "/responseTimeDetails",
      },
      {
        name: "deliveryTimeDetails",
        title: "deliveryTimeDetails",
        component: DeliveryTimeDetails,
        path: "/deliveryTimeDetails",
      },
      {
        name: "satisfactionScoreDetails",
        title: "satisfactionScoreDetails",
        component: SatisfactionScoreDetails,
        path: "/satisfactionScoreDetails",
      },
      {
        name: "racesProgressDetailsWithId",
        title: "racesProgressDetails",
        component: RacesProgressDetails,
        path: "/racesProgress/:id",
      },
      {
        name: "racesProgressDetailsWithoutId",
        title: "racesProgressDetails",
        component: RacesProgressDetails,
        path: "/racesProgress/",
      },
      {
        name: "issuedParcelsDetails",
        title: "issuedParcelsDetails",
        component: IssuedParcelsDetails,
        path: "/issuedParcelsDetails",
      },
      {
        name: "profile",
        title: "profile",
        component: Profile,
        path: "/profile",
      },
    ],
  },
];

export const Routes = renderRoutes(routes);
