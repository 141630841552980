import { useSelector } from "react-redux";
import ModalsHeadline from "../components/ModalsHeadline";
import { useEffect, useState } from "react";
import { DocumentSvg } from "../../icons";

const PicturesListModal = ({ parcelId, handleShowModal }) => {
  const [openedParcel, setOpenedParcel] = useState(null);
  const { parcels } = useSelector((state) => state?.groups?.parcels);

  useEffect(() => {
    const openedParcel = parcels?.find((item) => item.id === parcelId);
    setOpenedParcel(openedParcel);
  }, [parcelId, parcels]);

  const handleOpenFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="flex flex-col justify-between rounded-lg h-full">
      <div>
        <ModalsHeadline text="Uploaded Pictures" onClick={handleShowModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          {openedParcel &&
            openedParcel?.parcelFiles.length > 0 &&
            openedParcel?.parcelFiles.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow cursor-pointer"
                onClick={() => handleOpenFile(file.fileUrl)}
              >
                <div className="flex items-center space-x-3">
                  <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                    <DocumentSvg />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-blue-900 truncate">
                      {file.fileName}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PicturesListModal;
