import { useSelector } from "react-redux";
import { useAppContext } from "../../libs/AppContext";
import { DeleteSvg, EditSvg } from "../icons";
import { useTranslation } from "react-i18next";
import CustomDrop from "../common/CustomDrop";
import Checkbox from "../common/Checkbox";
import CheckAll from "../common/CheckAll";
import Spinner from "../common/Spinner";

const CategoriesTable = ({
  handleShowEditCategory,
  handleDeleteConfirmation,
}) => {
  const { isPermission } = useAppContext();
  const { categories, isLoading } = useSelector((state) => state.categories);
  const { t } = useTranslation();

  const columns = [
    { header: t("text.name"), key: "name" },
    { header: t("text.bonusType"), key: "bonusType" },
    { header: t("text.bonusValue"), key: "bonusValue" },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className="max-w-5 text-left border-b font-medium"
                  >
                    <div className="p-4 w-full truncate border-r border-black/5">
                      {column.header}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {categories?.map((row, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 border-black/5 border-b"
                >
                  <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                    <Checkbox />
                  </td>
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className="max-w-5 text-xs text-blue-4 font-medium"
                    >
                      <div className="py-2 px-3 w-full truncate border-r border-black/5">
                        {row[column.key]}
                      </div>
                    </td>
                  ))}
                  <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                    <div className="flex gap-2">
                      <CustomDrop>
                        {isPermission.delegationDeleteCategory?.action ===
                          "delegationDeleteCategory" && (
                          <div onClick={() => handleDeleteConfirmation(row.id)}>
                            <DeleteSvg />
                            {t("text.delete")}
                          </div>
                        )}
                        {isPermission.delegationChangeCategory?.action ===
                          "delegationChangeCategory" && (
                          <div onClick={() => handleShowEditCategory(row.id)}>
                            <EditSvg />
                            {t("text.edit")}
                          </div>
                        )}
                      </CustomDrop>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CategoriesTable;
